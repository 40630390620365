import {
  AdminMenu,
  AdminMenuBphtb,
  ApplicationsBphtb,
  ApplicationsPbb,
  DaftarPermohonan,
  DaftarSPPD,
  Dashboard,
  Koreksi,
  Laporan,
  Logout,
  MasterData,
  Piutang,
  Realisasi,
  Settings,
  Transaksi,
  WajibPajak,
} from './menu'
import {AdminMenuPBB} from './menu/_admin-menu-pbb'
import {ApplicationsPbbPelayanan} from './menu/_applications-pbb-pelayanan'
import {Lapangan} from './menu/_lapangan'

export type IGuardRole =
  | 'superadmin'
  | 'admin'
  | 'operator'
  | 'kasubbid'
  | 'kabid'
  | 'kecamatan'
  | 'kelurahan'
  | 'notaris'
  | 'umum'
  | 'admin_kolektor'
  | 'admin_bphtb'
  | 'admin_pbb'

export interface IMenu {
  title?: string
  link?: string
  fontIcon?: string
  customMenu?: string
  rightTextCodeEl?: string
  icon?: string
  subItems?: IMenu[]
  adjustment?: string[]
  isVisible?: boolean
  guards?: IGuardRole[]
  guardSubBidang?: string[]
  guardBidang?: string[]
  calculation?: (data: string) => string
}

export type Menu = {
  nama_menu?: string
  link?: string
  icon?: string
  is_main_menu?: string
  uuid_menu?: string
  child?: Menu[]
  parentId?: string[]
}

/*
|--------------------------------------------------------------------------
| Menu Configuration
|--------------------------------------------------------------------------
|
| Here we will load the environment and create the application instance
| that serves as the central piece of this framework. We'll use this
| application as an "IoC" container and router for this framework.
|
*/

export const DefautlConfigHeader: IMenu[] = [
  {
    title: 'Dashboard',
    link: '/dashboard',
    guards: [
      'admin',
      'kasubbid',
      'kabid',
      'kecamatan',
      'kelurahan',
      'superadmin',
      'notaris',
      'umum',
      'operator',
      'admin_bphtb',
      'admin_pbb',
    ],
  },
  {
    title: 'Layanan',
    link: '/pelayanan/layanan',
    guards: ['kabid'],
  },
  // @RiskiManik @EffenrilAgung dummy menu pelayanan baru
  {
    title: 'Pelayanan Baru',
    link: '/pelayanan/v2/layanan-baru/',
    guards: ['operator'],
    guardSubBidang: ['pelayanan'],
  },
  // end dummy menu pelayanan baru
  // {
  //   title: 'Pelayanan  ',
  //   link: '/pelayanan/v2/layanan-baru',
  //   guards: ['operator'],
  //   guardSubBidang: ['pelayanan'],
  // },
  {
    title: 'Pelayanan Baru',
    link: '/pelayanan/v2/layanan-baru/',
    guards: ['admin', 'admin_pbb', 'superadmin'],
  },
  {
    title: 'Pendataan',
    link: '/pelayanan/form-service',
    guards: ['notaris', 'operator'],
    guardSubBidang: ['pendataan & penetapan'],
  },
]

export const DefaultConfig: IMenu[] = [
  ...Dashboard,
  ...MasterData,
  ...DaftarPermohonan,
  ...WajibPajak,
  ...DaftarSPPD,
  ...Realisasi,
  ...Piutang,
  ...Lapangan,
  ...ApplicationsPbb,
  ...ApplicationsPbbPelayanan,
  ...ApplicationsBphtb,
  ...Transaksi,
  ...Laporan,
  ...Koreksi,
  ...Settings,
  ...AdminMenu,
  ...AdminMenuBphtb,
  ...AdminMenuPBB,
  ...Logout,
]
