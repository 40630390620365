import axios from "axios"
import { ResponseAllMenu, PostMenuResponse, TypeResponseListMenu, TypePostMenu, TypeApiResponseBidang, TypeApiResponseSubBidang, TypeApiResponseListMenu } from "../../../../../../_metronic/layout/components/core/request-menu/types/role-types"

export const postMenu = async (data) => {
    const endpoint = 'menus/v2/store'
    const res = await axios.post<PostMenuResponse>(endpoint, data)
    return res.data
}


export const getAllMenu = async (): Promise<ResponseAllMenu> => {
    const endpoint = 'menus/v2/data'
    const res = await axios.get<ResponseAllMenu>(endpoint)
    return res.data
}

export const getListMenu = async (): Promise<TypeResponseListMenu> => {
    const endpoint = 'menus/v2/list'
    const res = await axios.get<TypeResponseListMenu>(endpoint)
    return res.data
}

export const getMenuByUuid = async (uuid: string) => {
    const endpoint = `menus/v2/get/${uuid}`
    const res = await axios.get(endpoint)
    return res.data
}

export const updateMenuByUuid = async (uuid: string, data) => {
    const endPoint = `menus/v2/update/${uuid}`
    const res = await axios.put(endPoint, data)
    return res.data
}

export const deleteMenu = async (uuid: string) => {
    const endpoint = `menus/v2/delete/${uuid}`
    const res = await axios.delete<PostMenuResponse>(endpoint)
    return res.data
}

export const getBidang = async (): Promise<TypeApiResponseBidang> => {
    const endpoint = 'bidang/data'
    const res = await axios.get<TypeApiResponseBidang>(endpoint)
    return res.data
}

export const postMenuList = async (data): Promise<PostMenuResponse> => {
    const endpoint = 'menus/list'
    const res = await axios.post<PostMenuResponse>(endpoint, data)
    return res.data
}

export const getMenuList = async (): Promise<TypeApiResponseListMenu> => {
    const endpoint = 'menus/list'
    const res = await axios.get<TypeApiResponseListMenu>(endpoint)
    return res.data
}

export const subBidang = async (uuid: string): Promise<TypeApiResponseSubBidang> => {
    const endpoint = `sub-bidang/get/bidang/${uuid}`
    const res = await axios.get<TypeApiResponseSubBidang>(endpoint)
    return res.data
}