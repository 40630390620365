/* eslint-disable array-callback-return */
import {FC, Fragment, memo} from 'react'
import {SidebarMenuItem} from '../../SidebarMenuItem'
import {SidebarMenuItemWithSub} from '../../SidebarMenuItemWithSub'
import {IMenu} from 'src/_metronic/layout/components/core/_menu-config'
import {ConditinalRendering} from 'src/app/widgets/molecule/conditional-rendering'
import {
  DataMenu,
  ParentMenu,
} from 'src/_metronic/layout/components/core/request-menu/types/role-types'
import LoopRenderer from 'src/app/widgets/molecule/loop-rendering'
import {useAuth} from 'src/app/modules/auth'

interface MenuProps {
  data: DataMenu
  guards: string
  title?: string
  adjustment?: string[]
  menuOperator: string
}

export const Menu: FC<MenuProps> = memo(({data, guards, menuOperator, adjustment, title}) => {
  const auth = useAuth()
  const checkGuardProtection = (role: string, guards?: string[]): boolean => {
    let bool: boolean = false
    if (!guards) {
      bool = true
    }
    if (guards.includes(role)) {
      bool = true
    }

    return bool
  }

  console.log(auth.currentUser.username)

  return (
    <Fragment>
      <ConditinalRendering
        conditional={checkGuardProtection(guards, data.roles)}
        onTrueComponent={
          <Fragment>
            <ConditinalRendering
              conditional={title !== undefined && adjustment?.includes(menuOperator)}
              onTrueComponent={
                <Fragment>
                  {/* <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                      <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                        {title}
                      </span>
                    </div>
                  </div> */}
                </Fragment>
              }
            />
            <LoopRenderer data={data.subItems}>
              {(subMenu: ParentMenu, index) => {
                return (
                  <>
                    {/*
                     *! INI ADA MENU KABID
                     */}
                    <ConditinalRendering
                      key={index}
                      conditional={
                        subMenu?.subItems === null &&
                        subMenu?.subItems.length > 0 &&
                        checkGuardProtection(subMenu.bidang, subMenu.roles)
                      }
                      onTrueComponent={
                        <SidebarMenuItem
                          to={subMenu.link!}
                          icon={subMenu.icon}
                          title={subMenu.title}
                          fontIcon={subMenu.icon}
                        />
                      }
                      onFalseComponent={
                        <ConditinalRendering
                          key={index}
                          conditional={subMenu?.subItems?.length > 0}
                          onTrueComponent={
                            <SidebarMenuItemWithSub
                              key={crypto.randomUUID()}
                              to={subMenu.link!}
                              title={subMenu.title!}
                              fontIcon={subMenu.icon}
                              icon={subMenu.icon}
                              isVisible={false}
                            >
                              {subMenu.subItems?.map((child: any) => (
                                <SidebarMenuItem
                                  key={crypto.randomUUID()}
                                  to={child.link ?? ''}
                                  rightText={child.rightTextCodeEl}
                                  title={child.nama_menu ?? child.title ?? ''}
                                  hasBullet={true}
                                />
                              ))}
                            </SidebarMenuItemWithSub>
                          }
                          onFalseComponent={
                            <SidebarMenuItem
                              key={crypto.randomUUID()}
                              to={subMenu.link}
                              icon={subMenu.icon}
                              title={
                                subMenu.title === 'Dashboard'
                                  ? `${subMenu.title} \n ${auth.currentUser.username}`
                                  : subMenu.title
                              }
                              fontIcon='duotune'
                            />
                          }
                        />
                      }
                    />
                  </>
                )
              }}
            </LoopRenderer>
          </Fragment>
        }
      />
    </Fragment>
  )
})
